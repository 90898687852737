.avatar {
	img {
		border-radius: 50%;
		margin-right: 10px;
	}
}
.circle-profile-photo {
	width: 60px;
	height: 60px;
}

.square-profile-photo {
	width: 60px;
	height: 60px;
}

.list-group .list-group-item {
	.circle-profile-photo {
		width: 40px;
		height: 40px;
		display: inline-block;
		vertical-align: middle;
		margin: 0 10px;
	}

	.square-profile-photo {
		width: 40px;
		height: 40px;
		display: inline-block;
		vertical-align: middle;
		margin: 0 10px;
	}

	.circle-sm {
		border-radius: 100%;
		text-align: center;
		line-height: 40px;
		width: 40px;
		height: 40px;
		font-size: 20px;
	}

	.circle-xs {
		border-radius: 100%;
		text-align: center;
		line-height: 30px;
		width: 30px;
		height: 30px;
		font-size: 30px;
		color: #fff;
		font-size: 16px;
	}
}

#content_sidebarnav-left.lg.twoUp {
	.square-profile-photo {
		width: 155px;
		height: 155px;
	}
}

.list-group.user-group .list-group-item {
	display: inline;
	position: relative;
	font-size: 0;
	margin: 0;
	padding: 0;

	.remove-guests {
		opacity: 0;
		filter: alpha(opacity=0);
		position: absolute;
		top: -36px;
		left: -18px;
		cursor: pointer;
		color: #f44336;
		z-index: 99;
		@include transition(all 250ms);

		i {
			font-size: 20px;
			-webkit-transform: scale(0);
			-moz-transform: scale(0);
			-ms-transform: scale(0);
			-o-transform: scale(0);
			transform: scale(0);
			-webkit-transition-delay: 0.2s;
			transition-delay: 0.2s;
			@include transition(all 250ms);
		}
	}
}

.user-group .list-group-item:hover {
	.remove-guests {
		cursor: pointer;
		opacity: 1;
		filter: alpha(opacity=100);

		i {
			font-size: 20px;
			-webkit-transform: scale(1);
			-moz-transform: scale(1);
			-ms-transform: scale(1);
			-o-transform: scale(1);
			transform: scale(1);
		}
	}
}

.list-group.user-group .list-group-item.active,
.list-group.user-group .list-group-item:focus,
.list-group.user-group .list-group-item:hover {
	background-color: #fff;
	cursor: default;
}

.user-group .list-group-item:not(.dropdown):hover img {
	-webkit-transform: scale(1.5);
	-moz-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-o-transform: scale(1.5);
	transform: scale(1.5);
	cursor: pointer;
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
	z-index: 10;
}

.user-group .list-group-item img {
	position: relative;
	margin-left: -13px;
	border: 2px solid #fff;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1) 1px 1px rgba(0, 0, 0, .1);
	-khtml-box-shadow: 0 5px 10px rgba(0, 0, 0, .1) 1px 1px rgba(0, 0, 0, .1);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .1) 1px 1px rgba(0, 0, 0, .1);
	-ms-box-shadow: 0 5px 10px rgba(0, 0, 0, .1) 1px 1px rgba(0, 0, 0, .1);
	-o-box-shadow: 0 5px 10px rgba(0, 0, 0, .1) 1px 1px rgba(0, 0, 0, .1);
	box-shadow: 0 5px 10px rgba(0, 0, 0, .1) 1px 1px rgba(0, 0, 0, .1);
	z-index: 1;
	@include transition(all 250ms);
}
