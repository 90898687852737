#footer_wrapper
{
	bottom: 0;
	width: 100%;
	background: #2196f3;

	.footer-content
	{
		text-align: left;
		padding: 0px 30px;

		ul
		{
			margin: 0;
			padding: 0;
			list-style: none;

			li
			{
				line-height: 2em;
				font-size: rem(13px);

				a,
				a:link,
				a:visited
				{
					color: rgba(255, 255, 255, .5);
					font-weight: 400;
				}

				a:active,
				a:hover
				{
					color: #fff;
				}
			}
		}

		h6,
		p,
		span
		{
			color: #fff;
		}

		h6
		{
			font-size: rem(18px);
			margin: 0 0 15px;
		}

		p
		{
			font-size: rem(13px);
			margin: 0;
		}

		span
		{
			font-size: rem(12px);
		}

		.copy-wrapper
		{
			padding: 10px 0 0;
			margin: 10px 0 0;

			.copy
			{
				color: rgba(255, 255, 255, .6);
				font-weight: 200;
			}
		}

		.form-group
		{
			.control-label
			{
				color: rgba(255, 255, 255, .6);
			}

			.form-control
			{
				background-image: none;
				border: none;
				margin: -5px 0 0;
				padding: 10px 20px;
				border-radius: 3px;
				height: 45px;
				display: inline-block;
				color: #fff;
				-webkit-transition: all 150ms ease-in-out;
				-moz-transition: all 150ms ease-in-out;
				-o-transition: all 150ms ease-in-out;
				transition: all 150ms ease-in-out;
				background-color: rgba(255, 255, 255, .2);
			}

			.input-group-btn
			{
				top: -4px;
			}

			.form-control:focus
			{
				//background-color: #fff;
				color: #4b5a6d;
				opacity: 1;
			}

			.input-group-addon
			{
				position: absolute;
				z-index: 15;
				left: 0;
				top: -1px;
				font-size: 25px;
				color: rgba(255, 255, 255, .6);
			}

			&.is-focused
			{
				.input-group-addon
				{
					i
					{
						color: #4b5a6d !important;
					}
				}
			}

			::-webkit-input-placeholder
			{
				color: rgba(255, 255, 255, .6) !important;
			}

			:-moz-placeholder
			{
				color: rgba(255, 255, 255, .6) !important;
			}

			::-moz-placeholder
			{
				color: rgba(255, 255, 255, .6) !important;
			}

			:-ms-input-placeholder
			{
				color: rgba(255, 255, 255, .6) !important;
			}

			input:focus::-webkit-input-placeholder
			{
				color: #4b5a6d !important;
			}

			input:focus:-moz-placeholder
			{
				color: #4b5a6d !important;
			}

			input:focus::-moz-placeholder
			{
				color: #4b5a6d !important;
			}

			input:focus:-ms-input-placeholder
			{
				color: #4b5a6d !important;
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, "sm"))
{
	#footer_wrapper
	{
		.footer-content
		{
			padding: 20px;

			h6
			{
				margin: 15px 0;
			}
		}
	}
}
