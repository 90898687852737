.boxedlayout3-wrapper {
	.content {
		h1 {
			padding: 20px 30px;
		}
		.content-body {
			padding-top: 30px;
		}
	}
}
