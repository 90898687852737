﻿/* datatables */

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th
{
	vertical-align: middle;
}


.dataTables_wrapper td a.icon
{
	text-indent: 0;
	text-align: center;

	> i
	{
		margin-right: 0;
	}
}

table.dataTable
{
	width: 100% !important;
	max-width: 100% !important;
	background: #fff;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	font-size: 14px;

	> thead
	{
		> tr
		{
			> th
			{
				background: #465e75;
				color: #fff;
			}

			> td
			{
			}
		}
	}

	thead > tr > th
	{
		padding: 15px 40px 15px 15px !important;
	}

	tbody > tr
	{
		&.child, &.child:hover
		{
			background: #fafafa !important;
			padding: 0;
			-webkit-box-shadow: inset 0 6px 6px -5px rgba(0, 0, 0, 0.1);
			box-shadow: inset 0 6px 6px -5px rgba(0, 0, 0, 0.1);

			> td.child
			{
				padding: 0;

				> ul
				{
					display: block;

					> li, > li:first-child
					{
						padding: 8px 10px;
						border-bottom: 1px dashed #ddd;

						&:last-child
						{
							border-bottom: 0;
						}

						> span.dtr-title
						{
							min-width: 0;

							&:after
							{
								display: inline-block;
								content: ":";
								margin-right: 5px;
							}
						}

						> span.dtr-data
						{
							.btn
							{
								> i
								{
									font-size: 1rem;
								}
							}
						}
					}
				}
			}
		}

		&.priority-urgent > td.sts-col
		{
			border-left: 4px solid #ef6a6a;
		}

		&.row-highlight > td.sts-col
		{
			border-left: 4px solid #fff;
			animation: row-highlight-blink .5s step-end infinite alternate;
		}

		@keyframes row-highlight-blink
		{
			50%
			{
				border-left-color: #eda246;
			}
		}

		> td
		{
			padding: 15px;

			&.sts-col
			{
				font-size: 22px;
				text-align: center;
			}

			&.adv-col
			{
				text-align: right;
				min-width: 100px;

				&.adv-col-3
				{
					min-width: 120px;
				}

				&.adv-col-4
				{
					min-width: 160px;
				}

				&.adv-col-5
				{
					min-width: 220px;
				}

				&.adv-col-6
				{
					min-width: 280px;
				}

				&.adv-col-sm
				{
					min-width: 20px;
				}

				&.adv-col-md
				{
					min-width: 200px;
				}

				> a
				{
					line-height: 40px;

					> i
					{
						line-height: 40px;
						font-size: 22px;
					}
				}

				.btn
				{
					margin-left: 10px;
					margin-right: 0;

					&:first-child
					{
						margin-left: 0;
					}
				}
			}

			a
			{
				color: #212529;

				&:hover
				{
					color: #E91E63;
				}
			}
		}
	}

	th.sorting_asc, th.sorting_desc
	{
	}
}

.ellipsis-col
{
	max-width: 300px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

div.dataTables_wrapper .table_pagination_container
{
	padding: 15px 10px 15px 15px;

	div.dataTables_info
	{
		margin: 12px 0 0 0;
		padding: 0;
		font-size: 12px;
		color: #787878;
	}
}

.no-data-available
{
	text-align: center;
}

/*.dataTables_empty
{
    display: none;
}*/

table.dataTable td, table.dataTable th
{
	vertical-align: middle;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.odd > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr.even > td:first-child
{
	position: relative;
	padding-left: 30px;
	cursor: pointer;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.odd > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.even > td:first-child:before
{
	display: block;
	padding: 0 5px;
	background: 0 0;
	color: #464646;
	border: 0;
	width: 20px;
	height: 20px;
	line-height: 20px;
	top: 50%;
	margin-top: -10px;
	font-size: 20px;
	left: 4px;
	position: absolute;
	border-radius: 14px;
	box-shadow: none;
	box-sizing: content-box;
	text-align: center;
	text-indent: 0 !important;
	font-family: 'Courier New',Courier,monospace;
	content: '+';
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before
{
	content: '-';
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after
{
	bottom: 18px;
}

div.dataTables_wrapper div.dataTables_processing
{
	padding: 8px 0;
	width: 180px;
	position: fixed;
	border-radius: 10px;

	> div
	{
		margin: 15px auto 0;

		> div
		{
			width: 6px;
			height: 6px;
		}
	}
}

.dataTables_wrapper
{
	/* pagination */
	> .row:nth-child(3)
	{
		.page-item:not(.previous):not(.next)
		{
			.page-link
			{
				display: block;
				margin: 0 5px;
				padding: 5px 10px;
				font-size: 13px;
				border: 0;
				-webkit-border-radius: 50%;
				border-radius: 50%;
			}

			&.active
			{
				.page-link
				{
					background: #42a5f5;
					border-color: #42a5f5;
				}
			}
		}

		.page-item.previous,
		.page-item.next
		{
			.page-link
			{
				display: block;
				margin: 0 5px;
				padding: 5px 10px;
				font-size: 13px;
				border: 0;
			}
		}
	}
}

@media(max-width:769px)
{

	div.dataTables_wrapper div.dataTables_paginate ul.pagination
	{
		justify-content: center;
	}
}
