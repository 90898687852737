.fullWidth1-wrapper {
	.content {
		h1 {
			padding: 20px 30px;
		}
		.content-body {
			min-height: 54.1vh !important;
			padding: 30px;
		}
	}
}
