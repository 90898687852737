﻿@import "_datatables.scss";
@import "_mixins.scss";
@import "_shared.scss";
@import "./main/util/_vars.scss";

a.logo-box
{
	width: 190px;
}

#app_main-menu-wrapper .nav-logo li .logo
{
	background: url('/assets/img/logo/logo-wide-alt.png');
	background-size: contain;
	background-repeat: no-repeat;
	width: 111px;
	height: auto;
	margin: 10px 0 0 15px;
}

.app_sidebar-menu-collapsed #app_main-menu-wrapper .nav-logo li .logo
{
	background: url('/assets/img/logo/logo-square.png');
	background-size: contain;
	margin: 8px 0 0 6px;
	background-repeat: no-repeat;
	border-radius: 50%;
	width: 28px;
	height: 28px !important;
}

.app_sidebar-menu-collapsed #app_main-menu-wrapper .nav-pills > li > a > i
{
	font-size: 1.28rem;
}

.app_sidebar-menu-collapsed #app_main-menu-wrapper .nav-pills
{
	padding-top: 0;
}

body #app_wrapper #content_wrapper .content .header-wrapper h1
{
	padding: 27px 15px;
	margin: 0;
}

body #app_wrapper #content_wrapper .content .header-wrapper.header-sm h1
{
	padding: 22px 15px;
}

body #app_wrapper #content_wrapper .content .header-wrapper.header-md
{
	height: 110px;
}

#footer_wrapper
{
	width: 100%;
}

#header_wrapper, #header_wrapper #header
{
	padding-left: 5px;
	padding-right: 5px;
}

.fullWidth3-wrapper
{
	.header-wrapper
	{
		smd-fab-speed-dial
		{
			position: absolute;
			right: 45px;
			top: 52px;
		}
	}
}

#app_topnavbar-wrapper .navbar .navbar-nav li.nav-item ul.dropdown-menu.dropdown-menu--langs li a
{
	padding: 10px;
}

#content_outer_wrapper #content_wrapper.card-overlay #header_wrapper.header-md + #content
{
	padding: 80px 20px 0;
}

.card-heading--search
{
	background: #fafafa;
	border-bottom: 1px solid #efefef;

	.form-group
	{
		margin-top: 0;
	}
}

#header_wrapper.header-md #header h1
{
	display: inline-block;
}

.breadcrumb
{
	padding: 8px 15px 8px 10px;
	display: inline;
	vertical-align: text-top;
}

.btn.disabled, .btn:disabled
{
	background-color: #bbb !important;
}

ul.card-actions.icons.right-top
{
	top: -28px;
}

.card-actions.icons.icons--custom > a,
.card-actions.icons.icons--custom > a:hover,
.card-actions.icons.icons--custom > li > a,
.card-actions.icons.icons--custom > li > a:hover
{
	height: 56px;
	min-width: 56px;
	width: 56px;
	line-height: 56px;
	color: #fff;
}

.card .card-heading.card-heading--filter
{
	background: #fafafa;
	border-bottom: 1px solid #ddd;
	margin-bottom: 30px;
	@include box-shadow(0, 2px, 6px, rgba(0,0,0,0.1));

	.form-group
	{
		margin-top: 0;
	}
}

.error.error-block
{
	position: static;
}

.pc-notification
{
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: -44px;
	width: 100%;
	left: 0;
	z-index: 9999;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;

	> .inner
	{
		max-width: 90%;
		background: #676767;
		padding: 10px 30px;
		color: #fff;
		text-align: center;
	}

	&.success > .inner
	{
		background: #28a745;
	}

	&.danger > .inner
	{
		background: #dc3545;
	}

	&.active
	{
		bottom: 0;
	}
}

.pc-form-filters
{
	button
	{
		margin-bottom: 8px;
	}
}

.pc-form-filters-legend
{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
	margin: 10px 0 0 0;
	padding: 10px 0 0 0;
	border-top: 1px dashed #ddd;

	> li
	{
		display: flex;
		align-items: center;
		margin: 0 16px 0 0;
		padding: 0;
		font-size: 15px;
		white-space: nowrap;

		> mat-icon
		{
			font-size: inherit;
			width: auto;
			height: auto;
			margin-right: 5px;
		}

		&:last-child
		{
			margin-right: 0;
		}
	}
}

.pc-actions-btn
{
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 99;
}

.pc-form-checkbox--box
{
	display: block;

	> div
	{
		border: 1px solid #ddd;
		padding: 10px;
		margin-bottom: 10px;
		border-radius: 5px;

		&:last-child
		{
			margin-bottom: 0;
		}
	}
}

.pc-form-radio--box
{
	mat-radio-button
	{
		margin-right: 15px;

		&:last-child
		{
			margin-right: 0;
		}

		.mdc-label
		{
			margin-bottom: 0;
		}
	}
}

.modal
{
	.modal-header
	{
		.close
		{
			color: #fff;
			line-height: 32px;
			text-align: center;
			display: block;
			width: 32px;
			height: 32px;
			margin: 0;
			padding: 0;
			font-size: 20px;
		}
	}

	.modal-footer > :not(:last-child)
	{
		margin-right: 16px;
	}

	.modal-footer > :not(:first-child)
	{
		margin-left: 0;
	}

	.modal-dialog
	{
		box-shadow: var(--mat-dialog-container-elevation-shadow, 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12));

		&.modal-xl
		{
			margin: 2% auto;
		}
	}
}

/* toaster */
.toast-container
{
	font-family: "Open Sans", sans-serif;
	font-size: 14px;
	font-weight: normal;

	&.toast-bottom-right
	{
		right: 30px;
		bottom: 4px;
	}

	.toast,
	.toast:hover
	{
		@include border-radius(2px);
		-webkit-box-shadow: none;
		box-shadow: none;

		.toast-title
		{
			font-weight: normal;
		}

		.toast-message
		{
			font-size: 12px;
		}
	}

	.toast-success
	{
		background-color: #5bb944;
	}

	.toast-error
	{
		background-color: #ff5252;
	}

	.toast-info
	{
		background-color: #40c4ff;
	}

	.toast-warning
	{
		background-color: #ffab40;
	}
}

.permissions-list
{
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;

	> li
	{
		display: block;
		border-bottom: 1px dashed #ddd;
		padding-bottom: 10px;
		margin-bottom: 10px;

		strong
		{
			display: block;
			margin-bottom: 10px;
		}

		ul
		{
			display: block;
			list-style: none;
			margin: 0;
			padding: 0;

			li
			{
				display: block;
			}
		}
	}
}

.logs-details--list
{
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;

	li
	{
		display: block;
		margin-bottom: 10px;
		padding-bottom: 10px !important;
		border-bottom: 1px dashed #ddd;
	}
}

.form-control:disabled,
.form-control[readonly]
{
	cursor: default;
	opacity: 0.5;
}

.breadcrumb
{
	display: block;
	margin: 0 0 0 15px;
	padding: 0;

	li.breadcrumb-item
	{
		display: inline-block;

		+ .breadcrumb-item
		{
			padding-left: 0;

			&::before
			{
				display: inline-block;
				padding-right: .5rem;
				color: #fff;
				content: "/";
			}
		}

		a
		{
			color: #fff;
		}
	}
}

/* form fields */
.form-group
{
	margin-bottom: 0;

	&.row
	{
		align-items: center;
	}

	label
	{
		font-weight: 400;
		font-size: .875rem;
	}
}

.mat-sidenav-content,
.mat-drawer-container,
.mat-sidenav-content,
.mat-drawer-content
{
	background-color: #eef5f9 !important;
	overflow: visible !important;
	overflow: initial !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill
{
	width: auto;
	display: block;

	.mdc-text-field
	{
		padding-left: 0;
		padding-right: 0;

		&.mdc-text-field--filled
		{
			background-color: transparent;

			.mdc-floating-label
			{
				font-family: $font-family-primary;
				font-size: 14px;
			}

			.mdc-line-ripple
			{
				&:before
				{
					border-bottom-color: rgba(0, 0, 0, 0.12);
				}

				&:after
				{
					border-bottom-width: 1.5px;
				}
			}

			&.mdc-text-field--disabled
			{
				.mdc-line-ripple::before
				{
					border-bottom: 1px dashed rgba(0, 0, 0, 0.18);
				}
			}

			.mdc-text-field__input
			{
				color: rgba(0, 0, 0, 0.87);
			}

			&.mdc-text-field--focused
			{
				.mat-mdc-form-field-focus-overlay
				{
					opacity: 0;
				}

				.mdc-floating-label
				{
					color: #3f51b5 !important;
				}
			}

			.mdc-floating-label
			{
				&.mdc-floating-label--float-above
				{
					font-size: 16px;
				}

				.mat-mdc-form-field-required-marker:after
				{
					color: #e91e63;
					margin-left: 3px;
				}
			}
		}
	}

	.mat-mdc-form-field-error-wrapper
	{
		left: auto;
		padding-left: 0;
		padding-right: 0;
	}

	.mat-mdc-form-field-hint-wrapper
	{
		padding-left: 0;
		padding-right: 0;
	}

	.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
	{
		.mat-mdc-form-field-infix
		{
			min-height: unset;
			padding-top: 24px;
			padding-bottom: 6px;
		}
	}

	&.mat-form-field-disabled
	{
		.mdc-text-field
		{
			padding-left: 0;
			padding-right: 0;

			&.mdc-text-field--filled
			{
				.mdc-floating-label
				{
					color: $pcode-color-secondary;

					.mat-mdc-form-field-required-marker
					{
						display: none;
					}
				}
			}
		}
	}

	&:hover
	{
		.mat-mdc-form-field-focus-overlay
		{
			opacity: 0;
		}
	}
}

.mat-input-element:disabled,
.mat-radio-disabled
.mat-radio-label-content,
.mat-checkbox-disabled
.mat-checkbox-label,
.mat-mdc-select-disabled,
.mat-form-field-disabled,
.mat-form-field-disabled input
{
	color: #6b7d97 !important;
	opacity: 1;
}

mat-hint
{
	width: 100%;
	text-align: right;

	+ .mat-mdc-form-field-hint-spacer
	{
		display: none;
	}
}

/* tabs */
.mat-mdc-tab-header
{
	background: #fff;
}

.mat-mdc-tab.mdc-tab
{
	min-width: 160px;
	font-weight: normal;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline
{
	border-color: #ec407a;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label
{
	color: inherit;
}

.content mat-tab-header .mat-mdc-tab-label-container
{
	padding-left: 30px;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar
{
	background-color: #ec407a !important;
}

.mat-mdc-tab-label
{
	.mat-mdc-tab
	{
		font-family: $font-family-primary;
	}

	&.mat-tab-label-active
	{
		opacity: 1;
	}
}

/* datepicker */
mat-datepicker-toggle
{
	width: 24px;
	height: 24px;

	.mat-mdc-icon-button.mat-mdc-button-base
	{
		padding: 0;
		width: inherit;
		height: inherit;
		margin-top: 15px;

		svg
		{
			width: 20px;
			height: 20px;
		}
	}
}

/* fab */
smd-fab-speed-dial.smd-left .smd-fab-speed-dial-container smd-fab-actions .smd-fab-action-item
{
	width: 40px;
	height: 40px;
	line-height: 40px;

	mat-icon
	{
		width: auto;
		height: auto;
		line-height: 40px;
	}
}

/* checkbox */
.mat-mdc-checkbox
{
	label
	{
		font-family: $font-family-primary;
	}

	.mdc-checkbox
	{
		padding: 0;

		.mdc-checkbox__background
		{
			top: 0;
			left: 0;
		}
	}

	label
	{
		padding-left: 8px !important;
	}
}

/* select */
.mat-mdc-select
{
	.mat-mdc-select-arrow-wrapper
	{
		transform: translateY(-2px) !important;
	}
}

/* button */
.mdc-button,
.mdc-button.mat-mdc-button-base,
.mdc-button.mat-mdc-unelevated-button.mat-mdc-button-base
{
	padding-left: 20px !important;
	padding-right: 20px !important;
	height: 40px;
	border-radius: 9999px;
	font-weight: normal;
	font-family: $font-family-primary;

	&.disabled, &:disabled
	{
		background-color: #bbb !important;
	}

	&.btn-xs
	{
		padding: 4px 15px;
		height: 30px;
		font-size: 12px;
	}
}

.mdc-form-field > label
{
	margin-bottom: 0;
}

/* mat slider */
.always-on-indicator
{
	&.mdc-slider .mdc-slider__value-indicator
	{
		transform: scale(1);
	}
}

/* errors */
mat-error
{
	font-size: 12px;
	color: var(--mat-form-field-error-text-color, var(--mat-app-error));
	font-family: var(--mat-form-field-subscript-text-font, var(--mat-app-body-small-font));
}

/* external label */
mat-label.mat-label-external
{
	display: block;
	color: var(--mdc-filled-text-field-label-text-color, var(--mat-app-on-surface-variant));
	font-family: "Poppins", sans-serif;
	font-size: 14px;

	&.mat-label-external--small
	{
		font-size: 13px;
	}
}

/* evita fascia bianca a fondo pagina (se scrollata) all'apertura del datepicker in modalità touch-ui */
.cdk-global-scrollblock
{
	top: 0 !important;
}

/* commons */
.flex-1
{
	flex: 1;
}

.line-through
{
	text-decoration: line-through;
}

/* media queries */
@media (max-width: 768px)
{
	.logo
	{
		width: 200px !important;
		height: auto !important;
		margin: 0 auto !important;
	}

	#login_content h1
	{
		margin-bottom: 30px !important;
	}
}
