.sidebar-overlay {
	position: fixed;
	border-top: solid thin #e6edf1;
	top: 0;
	bottom: 0;
	right: -330px;
	width: 320px;
	height: 100%;
	margin-top: 0;
	background-color: #fff;
	overflow: auto;
	box-shadow: none;
	-webkit-overflow-scrolling: touch;
	z-index: $zindex_sidebar_menu_wrapper;
	@include transition(all 0.2s);

	ul.description {
		margin: 5px 20px;
		padding: 0;
		list-style: none;

		li.title {
			text-transform: uppercase;
			font-weight: 500;
			font-size: rem(14px);
		}
	}

	.tab-content {
		padding: 20px 0;
	}
	.mini.status.badge {
		width: 10px;
		height: 10px;
		position: absolute;
		top: 12px;
		left: 49px;
		border: 1px solid #fff;
	}
}

.sidebar-overlay-open {
	#app_sidebar-right {
		.sidebar-overlay {
			right: 0;
			box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2),
				0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
			z-index: $zindex_rightsidebar_wrapper;
			@include transition(all 0.2s);
		}
	}

	.backdrop {
		display: block;
	}
}

.sidebar-timeline {
	border-left: 2px solid #4285f4;
	position: relative;
	margin: 20px;
}

.sidebar-timeline .time-item:after {
	background-color: #ffffff;
	border-color: #4285f4;
	border-radius: 10px;
	border-style: solid;
	border-width: 2px;
	bottom: 0;
	content: '';
	height: 10px;
	left: 0;
	margin-left: -6px;
	position: absolute;
	top: 5px;
	width: 10px;
}

.time-item {
	border-color: #4285f4;
	padding-bottom: 10px;
	position: relative;
}

.time-item:before {
	content: " ";
	display: table;
}

.time-item:after {
	background-color: #ffffff;
	border-color: #039cfd;
	border-radius: 10px;
	border-style: solid;
	border-width: 2px;
	bottom: 0;
	content: '';
	height: 14px;
	left: 0;
	margin-left: -8px;
	position: absolute;
	top: 5px;
	width: 14px;
}

.time-item-item:after {
	content: " ";
	display: table;
}

.item-info {
	margin-bottom: 15px;
	margin-left: 15px;
}

.item-info p {
	font-size: 13px;
}
