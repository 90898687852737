// Red
$md-red-50: #ffebee!default;
$md-red-100: #ffcdd2!default;
$md-red-200: #ef9a9a!default;
$md-red-300: #e57373!default;
$md-red-400: #ef5350!default;
$md-red-500: #f44336!default;
$md-red-600: #e53935!default;
$md-red-700: #d32f2f!default;
$md-red-800: #c62828!default;
$md-red-900: #b71c1c!default;
$md-red-a100: #ff8a80!default;
$md-red-a200: #ff5252!default;
$md-red-a400: #ff1744!default;
$md-red-a700: #d50000!default;

// Pink
$md-pink-50: #fce4ec!default;
$md-pink-100: #f8bbd0!default;
$md-pink-200: #f48fb1!default;
$md-pink-300: #f06292!default;
$md-pink-400: #ec407a!default;
$md-pink-500: #e91e63!default;
$md-pink-600: #d81b60!default;
$md-pink-700: #c2185b!default;
$md-pink-800: #ad1457!default;
$md-pink-900: #880e4f!default;
$md-pink-a100: #ff80ab!default;
$md-pink-a200: #ff4081!default;
$md-pink-a400: #f50057!default;
$md-pink-a700: #c51162!default;

// Purple
$md-purple-50: #f3e5f5!default;
$md-purple-100: #e1bee7!default;
$md-purple-200: #ce93d8!default;
$md-purple-300: #ba68c8!default;
$md-purple-400: #ab47bc!default;
$md-purple-500: #9c27b0!default;
$md-purple-600: #8e24aa!default;
$md-purple-700: #7b1fa2!default;
$md-purple-800: #6a1b9a!default;
$md-purple-900: #4a148c!default;
$md-purple-a100: #ea80fc!default;
$md-purple-a200: #e040fb!default;
$md-purple-a400: #d500f9!default;
$md-purple-a700: #aa00ff!default;

// Deep Purple
$md-deep-purple-50: #ede7f6!default;
$md-deep-purple-100: #d1c4e9!default;
$md-deep-purple-200: #b39ddb!default;
$md-deep-purple-300: #9575cd!default;
$md-deep-purple-400: #7e57c2!default;
$md-deep-purple-500: #673ab7!default;
$md-deep-purple-600: #5e35b1!default;
$md-deep-purple-700: #512da8!default;
$md-deep-purple-800: #4527a0!default;
$md-deep-purple-900: #311b92!default;
$md-deep-purple-a100: #b388ff!default;
$md-deep-purple-a200: #7c4dff!default;
$md-deep-purple-a400: #651fff!default;
$md-deep-purple-a700: #6200ea!default;

// Indigo
$md-indigo-50: #e8eaf6!default;
$md-indigo-100: #c5cae9!default;
$md-indigo-200: #9fa8da!default;
$md-indigo-300: #7986cb!default;
$md-indigo-400: #5c6bc0!default;
$md-indigo-500: #3f51b5!default;
$md-indigo-600: #3949ab!default;
$md-indigo-700: #303f9f!default;
$md-indigo-800: #283593!default;
$md-indigo-900: #1a237e!default;
$md-indigo-a100: #8c9eff!default;
$md-indigo-a200: #536dfe!default;
$md-indigo-a400: #3d5afe!default;
$md-indigo-a700: #304ffe!default;

// Blue
$md-blue-50: #e3f2fd!default;
$md-blue-100: #bbdefb!default;
$md-blue-200: #90caf9!default;
$md-blue-300: #64b5f6!default;
$md-blue-400: #42a5f5!default;
$md-blue-500: #2196f3!default;
$md-blue-600: #1e88e5!default;
$md-blue-700: #1976d2!default;
$md-blue-800: #1565c0!default;
$md-blue-900: #0d47a1!default;
$md-blue-a100: #82b1ff!default;
$md-blue-a200: #448aff!default;
$md-blue-a400: #2979ff!default;
$md-blue-a700: #2962ff!default;

// Light Blue
$md-light-blue-50: #e1f5fe!default;
$md-light-blue-100: #b3e5fc!default;
$md-light-blue-200: #81d4fa!default;
$md-light-blue-300: #4fc3f7!default;
$md-light-blue-400: #29b6f6!default;
$md-light-blue-500: #03a9f4!default;
$md-light-blue-600: #039be5!default;
$md-light-blue-700: #0288d1!default;
$md-light-blue-800: #0277bd!default;
$md-light-blue-900: #01579b!default;
$md-light-blue-a100: #80d8ff!default;
$md-light-blue-a200: #40c4ff!default;
$md-light-blue-a400: #00b0ff!default;
$md-light-blue-a700: #0091ea!default;

// Cyan
$md-cyan-50: #e0f7fa!default;
$md-cyan-100: #b2ebf2!default;
$md-cyan-200: #80deea!default;
$md-cyan-300: #4dd0e1!default;
$md-cyan-400: #26c6da!default;
$md-cyan-500: #00bcd4!default;
$md-cyan-600: #00acc1!default;
$md-cyan-700: #0097a7!default;
$md-cyan-800: #00838f!default;
$md-cyan-900: #006064!default;
$md-cyan-a100: #84ffff!default;
$md-cyan-a200: #18ffff!default;
$md-cyan-a400: #00e5ff!default;
$md-cyan-a700: #00b8d4!default;

// Teal
$md-teal-50: #e0f2f1!default;
$md-teal-100: #b2dfdb!default;
$md-teal-200: #80cbc4!default;
$md-teal-300: #4db6ac!default;
$md-teal-400: #26a69a!default;
$md-teal-500: #009688!default;
$md-teal-600: #00897b!default;
$md-teal-700: #00796b!default;
$md-teal-800: #00695c!default;
$md-teal-900: #004d40!default;
$md-teal-a100: #a7ffeb!default;
$md-teal-a200: #64ffda!default;
$md-teal-a400: #1de9b6!default;
$md-teal-a700: #00bfa5!default;

// Green
$md-green-50: #e8f5e9!default;
$md-green-100: #c8e6c9!default;
$md-green-200: #a5d6a7!default;
$md-green-300: #81c784!default;
$md-green-400: #66bb6a!default;
$md-green-500: #4caf50!default;
$md-green-600: #43a047!default;
$md-green-700: #388e3c!default;
$md-green-800: #2e7d32!default;
$md-green-900: #1b5e20!default;
$md-green-a100: #b9f6ca!default;
$md-green-a200: #69f0ae!default;
$md-green-a400: #00e676!default;
$md-green-a700: #00c853!default;

// Light Green
$md-light-green-50: #f1f8e9!default;
$md-light-green-100: #dcedc8!default;
$md-light-green-200: #c5e1a5!default;
$md-light-green-300: #aed581!default;
$md-light-green-400: #9ccc65!default;
$md-light-green-500: #8bc34a!default;
$md-light-green-600: #7cb342!default;
$md-light-green-700: #689f38!default;
$md-light-green-800: #558b2f!default;
$md-light-green-900: #33691e!default;
$md-light-green-a100: #ccff90!default;
$md-light-green-a200: #b2ff59!default;
$md-light-green-a400: #76ff03!default;
$md-light-green-a700: #64dd17!default;

// Lime
$md-lime-50: #f9fbe7!default;
$md-lime-100: #f0f4c3!default;
$md-lime-200: #e6ee9c!default;
$md-lime-300: #dce775!default;
$md-lime-400: #d4e157!default;
$md-lime-500: #cddc39!default;
$md-lime-600: #c0ca33!default;
$md-lime-700: #afb42b!default;
$md-lime-800: #9e9d24!default;
$md-lime-900: #827717!default;
$md-lime-a100: #f4ff81!default;
$md-lime-a200: #eeff41!default;
$md-lime-a400: #c6ff00!default;
$md-lime-a700: #aeea00!default;

// Yellow
$md-yellow-50: #fffde7!default;
$md-yellow-100: #fff9c4!default;
$md-yellow-200: #fff59d!default;
$md-yellow-300: #fff176!default;
$md-yellow-400: #ffee58!default;
$md-yellow-500: #ffeb3b!default;
$md-yellow-600: #fdd835!default;
$md-yellow-700: #fbc02d!default;
$md-yellow-800: #f9a825!default;
$md-yellow-900: #f57f17!default;
$md-yellow-a100: #ffff8d!default;
$md-yellow-a200: #ffff00!default;
$md-yellow-a400: #ffea00!default;
$md-yellow-a700: #ffd600!default;

// Amber
$md-amber-50: #fff8e1!default;
$md-amber-100: #ffecb3!default;
$md-amber-200: #ffe082!default;
$md-amber-300: #ffd54f!default;
$md-amber-400: #ffca28!default;
$md-amber-500: #ffc107!default;
$md-amber-600: #ffb300!default;
$md-amber-700: #ffa000!default;
$md-amber-800: #ff8f00!default;
$md-amber-900: #ff6f00!default;
$md-amber-a100: #ffe57f!default;
$md-amber-a200: #ffd740!default;
$md-amber-a400: #ffc400!default;
$md-amber-a700: #ffab00!default;

// Orange
$md-orange-50: #fff3e0!default;
$md-orange-100: #ffe0b2!default;
$md-orange-200: #ffcc80!default;
$md-orange-300: #ffb74d!default;
$md-orange-400: #ffa726!default;
$md-orange-500: #ff9800!default;
$md-orange-600: #fb8c00!default;
$md-orange-700: #f57c00!default;
$md-orange-800: #ef6c00!default;
$md-orange-900: #e65100!default;
$md-orange-a100: #ffd180!default;
$md-orange-a200: #ffab40!default;
$md-orange-a400: #ff9100!default;
$md-orange-a700: #ff6d00!default;

// Deep Orange
$md-deep-orange-50: #fbe9e7!default;
$md-deep-orange-100: #ffccbc!default;
$md-deep-orange-200: #ffab91!default;
$md-deep-orange-300: #ff8a65!default;
$md-deep-orange-400: #ff7043!default;
$md-deep-orange-500: #ff5722!default;
$md-deep-orange-600: #f4511e!default;
$md-deep-orange-700: #e64a19!default;
$md-deep-orange-800: #d84315!default;
$md-deep-orange-900: #bf360c!default;
$md-deep-orange-a100: #ff9e80!default;
$md-deep-orange-a200: #ff6e40!default;
$md-deep-orange-a400: #ff3d00!default;
$md-deep-orange-a700: #dd2c00!default;

// Brown
$md-brown-50: #efebe9!default;
$md-brown-100: #d7ccc8!default;
$md-brown-200: #bcaaa4!default;
$md-brown-300: #a1887f!default;
$md-brown-400: #8d6e63!default;
$md-brown-500: #795548!default;
$md-brown-600: #6d4c41!default;
$md-brown-700: #5d4037!default;
$md-brown-800: #4e342e!default;
$md-brown-900: #3e2723!default;

// Grey
$md-grey-50: #fafafa!default;
$md-grey-100: #f5f5f5!default;
$md-grey-200: #eeeeee!default;
$md-grey-300: #e0e0e0!default;
$md-grey-400: #bdbdbd!default;
$md-grey-500: #9e9e9e!default;
$md-grey-600: #757575!default;
$md-grey-700: #616161!default;
$md-grey-800: #424242!default;
$md-grey-900: #212121!default;

// Blue Grey
$md-blue-grey-50: #eceff1!default;
$md-blue-grey-100: #cfd8dc!default;
$md-blue-grey-200: #b0bec5!default;
$md-blue-grey-300: #90a4ae!default;
$md-blue-grey-400: #78909c!default;
$md-blue-grey-500: #607d8b!default;
$md-blue-grey-600: #546e7a!default;
$md-blue-grey-700: #455a64!default;
$md-blue-grey-800: #37474f!default;
$md-blue-grey-900: #263238!default;

// Defaults
$md-red: $md-red-500!default;
$md-pink: $md-pink-500!default;
$md-purple: $md-purple-500!default;
$md-deep-purple: $md-deep-purple-500!default;
$md-indigo: $md-indigo-500!default;
$md-blue: $md-blue-500!default;
$md-light-blue: $md-light-blue-500!default;
$md-cyan: $md-cyan-500!default;
$md-teal: $md-teal-500!default;
$md-green: $md-green-500!default;
$md-light-green: $md-light-green-500!default;
$md-lime: $md-lime-500!default;
$md-yellow: $md-yellow-500!default;
$md-amber: $md-amber-500!default;
$md-orange: $md-orange-500!default;
$md-deep-orange: $md-deep-orange-500!default;
$md-brown: $md-brown-500!default;
$md-grey: $md-grey-500!default;
$md-blue-grey: $md-blue-grey-500!default;

$black: #000000!default;
$rgb-black: "0,0,0" !default;
$white: #ffffff!default;
$rgb-white: "255,255,255" !default;
