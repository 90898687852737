﻿.mr-10
{
	margin-right: 10px;
}

.mr-20
{
	margin-right: 20px;
}

.mr-30
{
	margin-right: 30px;
}

.ml-10
{
	margin-left: 10px;
}

.ml-20
{
	margin-left: 20px;
}

.ml-30
{
	margin-left: 30px;
}

.mt-5
{
	margin-top: 5px !important;
}

.mt-10
{
	margin-top: 10px;
}

.mt-15
{
	margin-top: 15px;
}

.mt-20
{
	margin-top: 20px;
}

.mt-30
{
	margin-top: 30px;
}

.mb-0i
{
	margin-bottom: 0px !important;
}

.mb-30
{
	margin-bottom: 30px;
}

.block
{
	display: block;
}

.pc-notify
{
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: -44px;
	width: 100%;
	left: 0;
	z-index: 9999;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;

	> .inner
	{
		max-width: 90%;
		background: #676767;
		padding: 10px 30px;
		color: #fff;
		text-align: center;
	}

	&.success > .inner
	{
		background: #28a745;
	}

	&.danger > .inner
	{
		background: #dc3545;
	}

	&.active
	{
		bottom: 0;
	}
}


.mce-branding
{
	display: none;
}

.mce-tinymce
{
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.mce-panel
{
	border-color: #ced4da;
}
