﻿
.mat-input-element:disabled,
.mat-radio-disabled .mat-radio-label-content,
.mat-checkbox-disabled .mat-checkbox-label
{
	color: #6b7d97 !important;
	opacity: 1;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle
{
	background-color: #6b7d97 !important;
}

.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle
{
	border-color: #6b7d97 !important;
}

.mat-select-disabled .mat-select-arrow-wrapper,
.mat-form-field-disabled mat-datepicker-toggle
{
	display: none;
}

.card-header .mat-mdc-checkbox .mdc-label
{
	margin-bottom: 0;
}

.pointer
{
	cursor: pointer;
}

label.required:after
{
	display: inline-block;
	content: "*";
	color: #d83939;
	font-size: 11px;
	line-height: 11px;
	position: absolute;
	right: 5px;
	top: 5px;
}

.list-group.list-group--divided .list-group-item
{
	border-bottom: 1px dashed #ddd;

	&:last-child
	{
		border-bottom: 0;
	}
}

.fullWidth2-wrapper .header-wrapper smd-fab-speed-dial
{
	position: absolute;
	right: 45px;
	top: 52px;
}

.blink
{
	animation: blink-animation 1s steps(5, start) infinite;
	-webkit-animation: blink-animation 1s steps(5, start) infinite;
}

div[role=progressbar][aria-busy=true]
{
	padding-top: 3px;
}

div[role=progressbar][aria-busy=true]::after,
div[role=progressbar][aria-busy=true]::before,
header [role=progressbar][aria-busy=true]::after,
header [role=progressbar][aria-busy=true]::before
{
	height: 3px;
}

#ajax-loader
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 3px;
	z-index: 999;
}

.cdk-overlay-container
{
	z-index: 9999 !important;
}

.cdk-global-overlay-wrapper
{
	background: rgba(255,255,255,0.7);
}

.modal-dialog.modal-xl
{
	width: 1400px;
	max-width: 95%;
}

.modal-dialog.modal-full-w
{
	width: 95vw;
	max-width: 95vw;
}

.modal-dialog.modal-md
{
	margin: 2% auto;
}

.modal-dialog.modal-lg
{
	margin: 2% auto;
}

#app_topnavbar-wrapper .hidden-sm-down
{
	display: block !important;
}

body #app_wrapper #app_topnavbar-wrapper .navbar-nav li a.notify
{
	> sup
	{
		top: -10px;
		left: 3px;
	}

	&.notify-active, &.notify-active:hover
	{
		background: #FF6F00;
		color: #fff;
	}
}

.rightSidenav2-wrapper
{
	.header-wrapper
	{
		.actions.fab.bottom-right
		{
			position: absolute;
			right: 45px;
			top: 80px;
		}
	}

	.nav-tabs li.active a:after
	{
		bottom: 0 !important;
	}
}

.mat-tab-header
{
	background: #fff;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar
{
	background-color: #ec407a;
}

.content mat-tab-header .mat-tab-label-container
{
	padding-left: 30px !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar
{
	background-color: #ec407a !important;
}

.mat-tab-label
{
	.mat-tab-label-content
	{
		font-family: "Open Sans",sans-serif;
	}

	&.mat-tab-label-active
	{
		opacity: 1;
	}
}

mat-tab-body
{
	font-family: "Open Sans", sans-serif;
}

.noselect
{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.card .card-block.datatable-nopadding
{
	padding: 0 0 .5em 0;

	.dataTables_info
	{
		padding-left: 15px;
	}
}

.pcode-dialog-panel--alert
{
	mat-dialog-container
	{
		border: 5px solid #f02424;
	}
}

.slide-toggle-box
{
	padding: 0 0 0 10px;
	border: 1px solid #ddd;
	border-bottom-width: 3px;
	border-radius: 10px;
	overflow: hidden;
	background: #e3ecf7;

	.mdc-switch
	{
		&.mdc-switch--disabled .mdc-switch__handle::after
		{
			background: #1d2833;
			opacity: 0.6;
		}
	}

	.mdc-switch + .mdc-label
	{
		display: block;
		padding: 10px;
		margin-left: 10px;
		background: #fff;
		color: #6b7d97;
	}
}

#InterventionsDatatable
{
	.intervention-details-row
	{
		background: #f5f5f5;
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
	}

	.table_small_info
	{
		display: block;
		font-weight: normal;
		color: #888;
		text-transform: uppercase;
		font-size: 12px;
		line-height: normal;
	}

	.intervention_status_box
	{
		position: relative;

		.interventions_list_icon_suffix
		{
			position: absolute;
			top: -12px;
			right: -6px;

			> .zmdi
			{
				font-size: 15px;
				line-height: normal;
			}
		}
	}

	.intervention_details_container
	{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;

		.intervention_details_list
		{
			flex: 1;
			display: block;
			list-style: none;
			margin: 0;
			padding: 10px;
			background: #fff;
			border: 1px solid #ddd;
			border-bottom-width: 3px;
			border-radius: 10px;

			> li
			{
				display: flex;
				align-items: flex-start;
				padding-bottom: 5px;
				margin-bottom: 5px;
				border-bottom: 1px dashed #ddd;

				> strong
				{
					margin-right: 8px;
					white-space: nowrap;
				}

				&:last-child
				{
					padding-bottom: 0;
					margin-bottom: 0;
					border-bottom: 0;
				}
			}
		}

		.intervention_work_teams
		{
			display: flex;
			list-style: none;
			margin: 0 15px 0 0;
			padding: 0;
			margin-bottom: 15px;


			> li
			{
				display: block;
				margin-right: 15px;
				background: #fff;
				border: 1px solid #ddd;
				border-bottom-width: 3px;
				border-radius: 10px;
				padding: 10px;

				> h3
				{
					display: flex;
					align-items: center;
					margin-bottom: 10px;
					padding-bottom: 5px;
					border-bottom: 1px solid #ddd;
					font-size: 16px;
					line-height: normal;

					> i
					{
						margin-right: 10px;
					}
				}

				> .intervention_work_teams_inner
				{
					display: flex;

					> .intervention_work_teams_left
					{
						> .intervention_work_teams_employees
						{
							display: block;
							list-style: none;
							margin: 0;
							padding: 0;

							> li
							{
								display: block;
								white-space: nowrap;

								&.evidence
								{
									color: #cc4bff;
								}
							}
						}

						> .intervention_work_teams_vehicles
						{
							display: block;
							list-style: none;
							margin: 0;
							padding: 0;

							> li
							{
								display: block;
								white-space: nowrap;
							}
						}
					}

					> .intervention_work_teams_right
					{
						border-left: 1px solid #ddd;
						margin-left: 15px;
						padding-left: 15px;

						> ul
						{
							display: block;
							list-style: none;
							margin: 0;
							padding: 0;

							> li
							{
								display: flex;
								align-items: flex-start;
								margin-bottom: 5px;
								padding-bottom: 5px;
								border-bottom: 1px dashed #ddd;
								white-space: nowrap;

								> strong
								{
									margin-right: 5px;
									white-space: nowrap;
								}

								&.wrap
								{
									white-space: normal;
								}

								&:last-child
								{
									margin-bottom: 0;
									padding-bottom: 0;
									border-bottom: 0;
								}
							}
						}
					}
				}

				&:last-child
				{
					margin-right: 0;
				}
			}
		}
	}
}

.customer_blacklist_alert
{
	padding: 10px;
	border: 1px solid #ddd;
	border-bottom-width: 3px;
	border-radius: 10px;

	&.customer_blacklist_alert--1
	{
		border-color: #ffc107;
	}

	&.customer_blacklist_alert--2
	{
		color: #dc3545;
		border-color: #dc3545;
	}
}

/* ### */
.p20
{
	padding: 20px;
}

.m-t-25
{
	margin-top: 25px;
}

.-ml-10
{
	margin-left: -10px;
}

.-m-t-5
{
	margin-top: -5px;
}

.font-weight-bold
{
	font-weight: 700 !important;
}

.font-weight-normal
{
	font-weight: normal;
}

.text-accent,
a.text-accent
{
	color: #ec407a;
}

.line-height-normal
{
	line-height: normal;
}

@keyframes blink-animation
{
	to
	{
		visibility: hidden;
	}
}

@-webkit-keyframes blink-animation
{
	to
	{
		visibility: hidden;
	}
}
